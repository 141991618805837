import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Me`}</h1>
    <p>{`I currently reside in the beautiful Pacific Northwest. I am a family man, I enjoy being outdoors, playing board games, video games, and have always been interested in technology.`}</p>
    <h2>{`Family`}</h2>
    <p>{`I'm a proud husband and father of two. Emily, Liam, and Dylan are my world. We spend our days at the bouncing on our trampoline, hiking, and playing at parks.`}</p>
    <div className="pure-g">
  <div className="pure-u-1 pure-u-md-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2FEmily-and-I.jpg?alt=media&token=d748e39f-9a7f-4ca3-8a17-dcc044c3b1b7" alt="Emily and I" />
  </div>
  <div className="pure-u-1 pure-u-md-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmyself-dylan-and-liam.jpg?alt=media&token=7b5fe7e7-ddbc-418b-bc55-9e1a4367d220" alt="From left to right; Me, Dylan, and Liam" />
  </div>
    </div>
    <h2>{`Archery`}</h2>
    <p>{`I've been shooting archery for over a year now and it is the most relaxing and enjoyable thing I've found in a long time.`}</p>
    <h3>{`2018`}</h3>
    <p>{`Another great year at Chamberlin 2018 traditional archery tournement. I hiked around with a some fun guys and ate some deliecious food.`}</p>
    <div className="pure-g">
  <div className="pure-u-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2FChamberlin%20Ranch%20April%202018.jpg?alt=media&token=ceac5306-a087-439e-9647-28101245b5b4" width="900" height="675" />
  </div>
  <div className="pure-u-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fcouple-of-the-guys-chamberlin-2018.jpg?alt=media&token=d4da9874-a97d-427b-bab0-a4d6feac19d1" width="900" height="675" />
  </div>
    </div>
    <h3>{`2017`}</h3>
    <p>{`I made it to the Chamberlin Archery Tournement in April 2017 where I met some great people and had a lot of fun shooting. I will definitely be back next year!`}</p>
    <div className="pure-g">
  <div className="pure-u-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fabout-me.jpg?alt=media&token=2663a14d-d826-4a0d-894e-bbc1c08aed1f" alt="Me durring archery competition" />
  </div>
  <div className="pure-u-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Farchery-turkey-shot.jpg?alt=media&token=52b393c1-1dc8-4d54-a40f-83d6880a1a42.jpg" alt="Turkey shot!!!" />
    <p className="p-0 m-0">
      This is a solid medal turkey with a 6 inch diameter. Myself, Jonothan, and
      Al made from about 20 yards 2016
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      